import * as React from 'react'

import { cn } from '@/lib/utils/utils'

const Page = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      id='PAGE'
      ref={ref}
      className={cn('bg-page text-page-foreground container flex h-full flex-1 flex-col', className)}
      {...props}
    />
  ))
Page.displayName = 'Page'

const PageHeader = React.forwardRef< HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      id='PAGE_HEADER'
      ref={ref}
      className={cn('flex flex-col gap-text p-page', className)}
      {...props}
    />
  ))
PageHeader.displayName = 'PageHeader'

const PageTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h1
      id='PAGE_TITLE'
      ref={ref}
      className={cn('text-pageTitle font-pageTitle leading-none tracking-tight', className)}
      {...props}
    />
  ))
PageTitle.displayName = 'PageTitle'

const PageDescription = React.forwardRef< HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <p
      id='PAGE_DESCRIPTION'
      ref={ref}
      className={'text-pageDesc ' + cn('font-pageDesc text-muted-foreground opacity-50', className)}
      {...props}
    />
  ))
PageDescription.displayName = 'PageDescription'

const PageContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      id='PAGE_CONTENT'
      ref={ref}
      className={cn('flex grow flex-col gap-page p-page pt-0', className)}
      {...props}
    />
  ))
PageContent.displayName = 'PageContent'

const PageFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      id='PAGE_FOOTER'
      ref={ref}
      className={cn('flex items-center gap-page p-page pt-0', className)}
      {...props}
    />
  ))
PageFooter.displayName = 'PageFooter'

export { Page, PageContent, PageDescription, PageFooter, PageHeader, PageTitle }

