import { SITE_IS_LB, siteConfig } from '@/config/site.config'
import Link from 'next/link'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { getLocalizedUri, I18nDictionary, useI18n } from '../i18n/lang-context'
import { LangMenuFlatHTML } from '../i18n/lang-toggle'
import { Button } from '../ui/button'
import { Flex } from '../ui/flex'
import { CookieConsentLink } from './cookies-dialog'
import { LogoHorizontal } from './logo'
import { Section } from './section'

const I18N: I18nDictionary = {
  copyright: { en: '© 2024 JBDA. All Rights Reserved.', fr: '© 2024 JBDA. Tous droits réservés.' },
  aboutUs: { en: 'About Us', fr: 'A Propos de Nous' },
  glossary: { en: 'Glossary', fr: 'Glossaire' },
  cgu: { en: 'T&Cs', fr: 'CGU' },
  legal: {
    title: { en: 'Legal links', fr: 'Pages légales' },
    legalMentions: { en: 'Legal', fr: 'Mentions légales' },
    privacyPolicy: { en: 'Privacy', fr: 'Confidentialité' }
  },
  landing: {
    targets: {
      title: { en: 'For whom?', fr: 'Pour qui ?' },
      regulated: { en: 'Regulated Professionnals', fr: 'Professions Réglementées' },
      immo: { en: 'Real Estate Professionnals', fr: 'Professionnels de l\'Immobilier' },
      crypto: { en: 'Crypto Owners', fr: 'Détenteurs de Crypto' }
    },
    usages: {
      title: { en: 'For what?', fr: 'Pourquoi ?' },
      immo: { en: 'Real Estate Transactions', fr: 'Transactions Immobilières' },
      pro: { en: 'Professional Investments', fr: 'Investissements Professionnels' },
      luxe: { en: 'Luxurious Purchases', fr: 'Achats de bien de luxe' }
    }
  }
}

export function Footer( { langCode }: { langCode: string}) {

  const i18n = useI18n(I18N)

  const SiteLink = ({ href, onClick, text }: { href?: string, onClick?: (e: React.MouseEvent) => void, text: string }) => (
    <Button aria-label='SITE_BUTTON' variant='link' size='sm' className='text-xs text-muted-foreground hover:text-foreground'>
      {href && <Link href={href}>{text}</Link>}
      {!href && onClick && <div onClick={e => onClick(e)}>{text}</div>}
    </Button>
  )

  const SocialLink = ({ href, icon }: { href: string, icon: React.ReactNode }) => (
    <Button aria-label='SOCIAL_BUTTON' variant='ghost' className='aspect-square p-2 text-muted-foreground hover:text-foreground hover:shadow-none'>
      <Link href={href} target='_blank' rel='noreferrer'>{icon}</Link>
    </Button>
  )

  return (
    <Section containerClassName='bg-background2'>
      <footer className='flex w-full flex-col items-center'>

        <LogoHorizontal bj={true} langCode={langCode} className='mb-12 mt-4 saturate-0 sm:mb-16 sm:mt-0' />

        {SITE_IS_LB && (<>
          <div className='pb-2 text-lg text-muted-foreground'>
            {i18n.landing.targets.title}
          </div>

          <Flex id='SITE_MAP' className='flex w-fit justify-center gap-2'>
            <SiteLink href={getLocalizedUri('/landing/targets/regulated')} text={i18n.landing.targets.regulated} />
            <SiteLink href={getLocalizedUri('/landing/targets/immo')} text={i18n.landing.targets.immo} />
            <SiteLink href={getLocalizedUri('/landing/targets/crypto')} text={i18n.landing.targets.crypto} />
          </Flex>

          {/* <div className=''>
            {i18n.landing.usages.title}
          </div>

          <Flex id='SITE_MAP' className='flex w-fit justify-center gap-2'>
            <SiteLink href={getLocalizedUri('/landing/usages/immo')} text={i18n.landing.usages.immo} />
            <SiteLink href={getLocalizedUri('/landing/usages/pro')} text={i18n.landing.usages.pro} />
            <SiteLink href={getLocalizedUri('/landing/usages/luxe')} text={i18n.landing.usages.luxe} />
          </Flex> */}

          <div className='my-2 h-[1px] w-10 bg-foreground opacity-30' />
        </>)}

        <LangMenuFlatHTML className='' />

        <div className='my-2 h-[1px] w-10 bg-foreground opacity-30' />

        <Flex id='LEGAL_LINKS' className='flex w-fit justify-center gap-2'>
          <SiteLink href={getLocalizedUri('/legal/legal-mentions')} text={i18n.legal.legalMentions} />
          <SiteLink href={getLocalizedUri('/legal/privacy-policy')} text={i18n.legal.privacyPolicy} />
          <SiteLink href={getLocalizedUri('/legal/cgu')} text={i18n.cgu} />
          {SITE_IS_LB &&
            <SiteLink href={getLocalizedUri('/about-us')} text={i18n.aboutUs} />
          }
          {SITE_IS_LB &&
            <SiteLink href={getLocalizedUri('/glossary')} text={i18n.glossary} />
          }
          <CookieConsentLink />
        </Flex>

        <div className='my-2 h-[1px] w-10 bg-foreground opacity-30' />

        <Flex id='SOCIAL_LINKS' className='w-full items-center justify-center gap-2'>
          <SocialLink href={siteConfig.links.linkedin} icon={<FaLinkedinIn />} />
          <SocialLink href={siteConfig.links.twitter} icon={<FaXTwitter />} />
        </Flex>

        <Flex id='COPYRIGHT' className='h-9 w-full items-center justify-center text-xs text-muted-foreground'>
          {i18n.copyright}
        </Flex>

      </footer>
    </Section>
  )
}
